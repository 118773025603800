<template>
  <input
    class="border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 rounded-md shadow-sm"
     v-model="value"
    ref="input"
  >
</template>

<script>
export default {
  data(){
    return{
      value: null,
    }
  },
  props: {
    modelValue: {
      type: String,
      default: ''
    }
  },
  watch:{
    value:{
      handler: function(newVal){
        this.$emit('input',newVal)
      }
    }
  },
  mounted() {
    // Focus the input if it has the autofocus attribute
    // if (this.$refs.input && this.$refs.input.hasAttribute('autofocus')) {
    //   this.$refs.input.focus();
    // }
  }
};
</script>
