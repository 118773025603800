<script>
export default {
  props: ['value']
};
</script>

<template>
  <label class="block font-medium text-sm text-gray-700 my-1">
    <span v-if="value" class="form-labels">{{ value }}</span>
    <span v-else>
      <slot />
    </span>
  </label>
</template>
