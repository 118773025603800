<script>
import appConfig from "@/app.config";
import { required, email } from "vuelidate/lib/validators";
import BreezeInput from "@/components/Input/input";
import Breezelabel from "@/components/Input/label";

/**
 * Forgot Password component
 */
export default {
  components:{
    BreezeInput,
    Breezelabel
  },
  page: {
    title: "Forgot Password",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  data() {
    return {
      processing:false,
      email: "",
      submitted: false,
      error: null,
      title: "Recoverpwd",
    };
  },
  validations: {
    email: {
      required,
      email,
    },
  },
  methods: {
    // Try to register the user in with the email, fullname
    // and password they provided.
    tryToReset() {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        let self = this;
        if(self.processing === true){
          return false;

        }
        self.processing = true;
        self.$axios.post('/reset-password-link' ,{'email':self.email}).then((response)=>{
          if(response.data.status !=true){
            self.triggerSwal(response.data.message, "error")
          }
          else{
            self.triggerSwal(response.data.message, "success")
          }
          self.processing = false;
        }).catch(error => {
          this.handleErrorResponse(error.response, "error");
        }).finally(function () {
        }); 
      }
    },
  },
};
</script>

<template>
  <div>
    
    <div class="account-page">
      <div class="container-fluid">
        <div class="row justify-content-center align-items-center">
          <div class="col-lg-6">
            <div>
              <div class="bg-white radius-20 p-3">
                <div class="text-center"><img src="@/assets/images/dashboard-logo.png" alt=""></div>
                <div class="w-75 mx-auto"> 
                  <div class="text-center mt-4">
                    <h5 class="f-24 text-dark fw-bold">Reset Password</h5>
                   
                  </div>
                  <div>
                    <div
                      class="text-center mb-4 fs-18"
                    >
                    Please provide the information to reset your password.
                                    
                    </div>
                    <form>
                      <div class="mb-3 form-group">
                       
                        <BreezeInput
                          type="email"
                          v-model="email"
                          class="form-control fs-14 rounded-pill px-4 input-padding"
                          id="useremail"
                          placeholder=""
                          :class="{
                            'is-invalid': submitted && $v.email.$error,
                          }"
                        />
                        <Breezelabel for="useremail"
                         class="form-label gray-text fs-14 px-2"
                         value=" Email ">Email 
                        </Breezelabel>
                        <div
                          v-if="submitted && $v.email.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.email.required"
                            >Email is required.</span
                          >
                          <span v-if="!$v.email.email"
                            >Please enter valid email.</span
                          >
                        </div>
                      </div>
                      <div class=" mb-0">
                        <div class="col-12 text-end border-orange rounded-pill p1 ">
                          <SubmitButton :processing="processing" text="Reset" @clicked="tryToReset" />
                          <!-- <button class="submit-btn gradientColor border-0 text-white btn radius-30 px-3" type="submit">
                            Reset
                          </button> -->
                        </div>
                      </div>
                      <div class="mt-4 text-center">
                        <p class="mb-0 dont-account">
                          Remember It ?
                          <router-link
                            to="/login"
                            class=""
                            >Login</router-link
                          >
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
                 
               
                <!-- end card-body -->
              </div>
              <!-- end card -->
            </div>
            <!-- end col -->
          </div>
          <div class="col-lg-6 fix-col d-lg-block d-none h-100 px-0">
          <img src="@/assets/images/reset.png" class="img-fluid w-100 h-100" alt="">

          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </div>
</template>

<style lang="scss" module></style>
